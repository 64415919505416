import { PageFooterData } from 'components/PageFooter/types/PageFooterData';

import { pageFooterDataEn } from './pageFooterData.en';
import { pageFooterDataEs } from './pageFooterData.es';
import { pageFooterDataPt } from './pageFooterData.pt';

export const pageFooterDataByLocale: Record<UserLocale, PageFooterData> = {
  en: pageFooterDataEn,
  es: pageFooterDataEs,
  pt: pageFooterDataPt,
};
