import { PageFooterData } from 'components/PageFooter/types/PageFooterData';
import { searchGetEventsRoutePath } from 'modules/search/routing/helpers/searchGetEventsRoutePath';
import { searchGetInternshipsRoutePath } from 'modules/search/routing/helpers/searchGetInternshipsRoutePath';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { searchGetOrgsRoutePath } from 'modules/search/routing/helpers/searchGetOrgsRoutePath';
import { searchGetVolopsRoutePath } from 'modules/search/routing/helpers/searchGetVolopsRoutePath';
import { postAListingRoute } from 'routing/routes';

export const pageFooterDataEn: PageFooterData = {
  findAJob: {
    title: 'Find a Job',
    links: [
      {
        type: 'jobs',
        title: 'Jobs',
        href: searchGetJobsRoutePath({ lang: 'en' }),
      },
      {
        type: 'internships',
        title: 'Internships',
        href: searchGetInternshipsRoutePath({ lang: 'en' }),
      },
      {
        type: 'organizations',
        title: 'Organizations',
        href: searchGetOrgsRoutePath({ lang: 'en' }),
      },
      {
        type: 'salary-explorer',
        title: 'Salary Explorer',
        href: '/nonprofit-salary-explorer',
      },
      {
        type: 'career-advice',
        title: 'Career Advice',
        href: '/en/careers',
      },
    ],
  },
  takeAction: {
    title: 'Take Action',
    links: [
      {
        type: 'volops',
        title: 'Volunteer',
        href: searchGetVolopsRoutePath({ lang: 'en' }),
      },
      {
        type: 'events',
        title: 'Attend an Event',
        href: searchGetEventsRoutePath({ lang: 'en' }),
      },
      {
        type: 'petitions',
        title: 'Sign a Petition',
        href: searchGetVolopsRoutePath({
          lang: 'en',
          filtersByName: { actionType: ['PETITION'] },
        }),
      },
      {
        type: 'donations',
        title: 'Make a Donation',
        href: searchGetVolopsRoutePath({
          lang: 'en',
          filtersByName: { actionType: ['DONATION'] },
        }),
      },
      {
        type: 'idealist-movement',
        title: 'Action Incubator',
        href: '/en/action-incubator/sessions',
      },
      {
        type: 'recipes-for-action',
        title: 'Recipes for Action',
        href: '/en/recipes-for-action',
      },
    ],
  },
  postOnIdealist: {
    title: 'Post on Idealist',
    links: [
      {
        type: 'post-a-listing',
        title: 'Post a listing',
        href: postAListingRoute.with({}),
      },
      {
        type: 'create-org',
        title: 'Add your Organization',
        href: '/organizations/create',
      },
      {
        type: 'create-community-group',
        title: 'Add your Community Group',
        href: '/organizations/create',
      },
      {
        type: 'employer-resources',
        title: 'Employer Resources',
        href: '/en/orgs/resources',
      },
      {
        type: 'for-grad-schools',
        title: 'For Grad Schools',
        href: '/grad-schools/exhibit',
      },
    ],
  },
  aboutUs: {
    title: 'About Us',
    links: [
      {
        type: 'our-mission',
        title: 'Our Mission',
        href: '/en/about',
      },
      {
        type: 'our-team',
        title: 'Our Team',
        href: '/en/about/our-team',
      },
      {
        type: 'our-partners',
        title: 'Our Partners',
        href: '/en/partners',
      },
      {
        type: 'data-insights',
        title: 'Data & Insights',
        href: '/en/insights',
      },
      {
        type: 'donate',
        title: 'Donate to Idealist',
        href: '/en/donate',
      },
    ],
  },
  learnMore: {
    title: 'Learn More',
    links: [
      {
        type: 'help-center',
        title: 'Help',
        href: '/en/help',
      },
      {
        type: 'contact-us',
        title: 'Contact Us',
        href: '/en/about/contact',
      },
      {
        type: 'terms-of-service',
        title: 'Terms of Service',
        href: '/en/terms-of-service',
      },
      {
        type: 'privacy-policy',
        title: 'Privacy Policy',
        href: '/en/privacy-policy',
      },
      {
        type: 'site-map',
        title: 'Site Map',
        href: '/en/sitemap',
      },
    ],
  },
};
