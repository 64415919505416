import { PageFooterData } from 'components/PageFooter/types/PageFooterData';
import { searchGetEventsRoutePath } from 'modules/search/routing/helpers/searchGetEventsRoutePath';
import { searchGetInternshipsRoutePath } from 'modules/search/routing/helpers/searchGetInternshipsRoutePath';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { searchGetOrgsRoutePath } from 'modules/search/routing/helpers/searchGetOrgsRoutePath';
import { searchGetVolopsRoutePath } from 'modules/search/routing/helpers/searchGetVolopsRoutePath';
import { postAListingRoute } from 'routing/routes';

export const pageFooterDataEs: PageFooterData = {
  findAJob: {
    title: 'Encuentra empleo',
    links: [
      {
        type: 'jobs',
        title: 'Empleos',
        href: searchGetJobsRoutePath({ lang: 'es' }),
      },
      {
        type: 'internships',
        title: 'Pasantías',
        href: searchGetInternshipsRoutePath({ lang: 'es' }),
      },
      {
        type: 'organizations',
        title: 'Organizaciones',
        href: searchGetOrgsRoutePath({ lang: 'es' }),
      },
      {
        type: 'career-advice',
        title: 'Desarrollo de carrera',
        href: '/es/accion?categories=empleocambiamundo&pq=',
      },
    ],
  },
  takeAction: {
    title: 'Actúa',
    links: [
      {
        type: 'volops',
        title: 'Voluntariado',
        href: searchGetVolopsRoutePath({ lang: 'es' }),
      },
      {
        type: 'events',
        title: 'Asiste a un Evento',
        href: searchGetEventsRoutePath({ lang: 'es' }),
      },
      {
        type: 'knowledge-base',
        title: 'Centro de Recursos para Recaudación de Fondos',
        href: '/es/recursos-recaudacion-fondos',
      },
      {
        type: 'recipes-for-action',
        title: 'Recetas para la Acción',
        href: '/es/recetas-para-accion',
      },
    ],
  },
  postOnIdealist: {
    title: 'Publica',
    links: [
      {
        type: 'post-a-listing',
        title: 'Publica una Oportunidad',
        href: postAListingRoute.with({}),
      },
      {
        type: 'create-org',
        title: 'Suma tu Organización',
        href: '/organizations/create',
      },
      {
        type: 'create-community-group',
        title: 'Suma tu Colectivo Ciudadano',
        href: '/organizations/create',
      },
      {
        type: 'employer-resources',
        title: 'Herramientas para Reclutadores',
        href: '/es/orgs/encuentra-talento',
      },
      {
        type: 'help-center',
        title: 'Centro de Ayuda',
        href: '/es/ayuda',
      },
    ],
  },
  aboutUs: {
    title: 'Acerca de',
    links: [
      {
        type: 'our-mission',
        title: '¿Qué buscamos?',
        href: '/es/about',
      },
      {
        type: 'our-team',
        title: 'Nuestro Equipo',
        href: '/es/acerca-de/nuestro-equipo',
      },
      {
        type: 'donate',
        title: 'Donar a Idealist',
        href: '/es/dona',
      },
    ],
  },
  learnMore: {
    title: 'Más información',
    links: [
      {
        type: 'help-center',
        title: 'Ayuda',
        href: '/es/ayuda',
      },
      {
        type: 'contact-us',
        title: 'Contáctanos',
        href: '/es/about/contactanos',
      },
      {
        type: 'terms-of-service',
        title: 'Términos de Servicio',
        href: '/en/terms-of-service',
      },
      {
        type: 'privacy-policy',
        title: 'Política de Privacidad',
        href: '/en/privacy-policy',
      },
      {
        type: 'site-map',
        title: 'Mapa del Sitio',
        href: '/es/mapasitio',
      },
    ],
  },
};
